import React, { useState } from 'react';
import { Box, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { Equipos } from './Equipos.js';
import { Jugadores } from './Jugadores.js';
import Modalidades from './Competiciones/Modalidades.js';
import { Boleras } from './Boleras.js';
import Noticias from './Noticias/Noticias.js';
import { useTema } from '../../Context/TemaContext.js';

export const TabsInicio = () => {
  const { tema } = useTema();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  // const [loading, setLoading] = useState(true);

  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box
      h={1}
      w={1}
      style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
    >
      <Tabs
        value={tab}
        variant='scrollable'
        scrollButtons='auto'
        onChange={handleChangeTab}
        style={{ color: tema.texto }}
      >
        <Tab label='Competiciones' style={{ color: tema.texto }} />
        <Tab label='Noticias' style={{ color: tema.texto }} />
        <Tab label='Peñas' style={{ color: tema.texto }} />
        <Tab label='Jugadores' style={{ color: tema.texto }} />
        <Tab label='Boleras' style={{ color: tema.texto }} />
        {/* <Tab label='Escuelas' disabled /> */}
      </Tabs>
      <Box style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        {tab === 0 && <Modalidades />}
        {tab === 1 && <Noticias />}
        {tab === 2 && <Equipos />}
        {tab === 3 && <Jugadores />}
        {tab === 4 && <Boleras />}
        {/* {tab === 5 && (
        <Escuelas/>
      )} */}
      </Box>
    </Box>
  );
};
