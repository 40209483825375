import React, { useEffect, useState } from 'react';
import 'moment/locale/es';
import { getRef } from '../../../../database/firebase.js';
import {
  Box,
  Tab,
  Tabs,
  styled,
  LinearProgress,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
// import ClasificacionConcurso from './Clasificacion/ClasificacionConcurso.js';
import ClasificacionFinal from './Clasificacion/ClasificacionFinal.js';
import ClasificacionCampeonato from './Clasificacion/ClasificacionCampeonato.js';
import TiradasConcurso from './Tiradas/TiradasConcurso.js';
import TiradasFinal from './Tiradas/TiradasFinal.js';
import InformacionConcurso from './Informacion/InformacionConcurso.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { RFPercentage } from '../../../../utils/Functions.js';
import { useData } from '../../../../Context/DataContext.js';
import { onSnapshot } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { useTema } from '../../../../Context/TemaContext.js';
import Texto from '../../../Componentes/Texto.js';
import moment from 'moment';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { theme } from '../../../../theme.js';

const Concursos = () => {
  const location = useLocation();
  const { concursoId, modalidad, tipos } = location.state;

  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const { tema } = useTema();
  const {
    concursos,
    buscarConcurso,
    categorias,
    buscarCategoria,
    categoriasFix,
  } = useData();

  const [año, setAño] = useState(moment().year());

  const concursosModalidad = useMemo(() => {
    return concursos.filter(
      (i) =>
        i.categorías.some((c) =>
          categorias.find((i) => i.modalidad === modalidad && c === i.id)
        ) &&
        i.fecha.includes(año) &&
        tipos.includes(i.tipo)
    );
  }, [modalidad, año, tipos]);

  const [concurso, setConcurso] = useState(buscarConcurso(concursoId));
  const [selectedConcurso, setSelectedConcurso] = useState(
    concursoId
      ? concursoId
      : concursosModalidad.length > 0
      ? concursosModalidad[0].id
      : 0
  );
  const [tab, setTab] = useState(1);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const [tiradas, setTiradas] = useState([]);

  const [fechas, setFechas] = useState([1, {}]);

  const [loading, setLoading] = useState(true);

  const suscribeTiradas = async () => {
    const tiradasRef = await getRef('tiradas', [
      {
        type: 'where',
        param: 'concursoId',
        condition: '==',
        value: selectedConcurso,
      },
      { type: 'orderBy', param: 'hora' },
    ]);

    const unsub = onSnapshot(tiradasRef, async (docsSnap) => {
      const tiradasArray = [];

      docsSnap.forEach((doc) => {
        const data = doc.data();
        tiradasArray.push({ ...data, id: doc.id });
      });

      tiradasArray.sort(function (a, b) {
        if (a.fecha < b.fecha) {
          return 1;
        }
        if (a.fecha > b.fecha) {
          return -1;
        }
        if (a.fecha === b.fecha) {
          if (a.hora > b.hora) {
            return 1;
          }
          if (a.hora < b.hora) {
            return -1;
          }
          if (a.hora === b.hora) {
            return 0;
          }
        }
      });
      setTiradas(tiradasArray);
      setLoading(false);
    });

    return unsub;
  };

  const cargarConcurso = async () => {
    const conc = buscarConcurso(selectedConcurso);
    if (conc) {
      if (conc.fechas !== undefined) {
        let newFechas = {};

        conc.fechas.forEach((fecha) => {
          newFechas[fecha] = {
            selected: true,
            selectedColor: tema.primary,
          };
        });

        if (conc.fechaFinal) {
          newFechas[conc.fechaFinal] = {
            selected: true,
            selectedColor: 'yellow',
          };
        }

        setFechas(newFechas);
      } else {
        let newFechas = {};

        newFechas[conc.fecha] = {
          selected: true,
          selectedColor: tema.primary,
        };

        setFechas(newFechas);
      }

      setConcurso(conc);
    }
  };

  useEffect(() => {
    if (selectedConcurso) {
      cargarConcurso();
    }
  }, [selectedConcurso]);

  useEffect(() => {
    if (selectedConcurso) {
      suscribeTiradas();
    }
  }, [selectedConcurso]);

  const onHandleChange = (event, value) => {
    if (value) {
      setLoading(true);
      setSelectedConcurso(value.id);
    }
  };

  const GroupItems = styled('ul')({
    padding: 0,
  });

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        ...(isMobile && {
          paddingLeft: 12,
          paddingRight: 12,
        }),
      }}
    >
      <Helmet>
        <title>{`Resultados de Bolos | ${
          tipos.includes('Final') ? 'Concursos' : 'Campeonatos'
        } | ${modalidad}`}</title>
      </Helmet>
      <Box
        style={{
          zIndex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: tema.backgroundColor,
          borderRadius: 12,
          overflow: 'hidden',
          paddingLeft: 12,
          paddingRight: 12,
          marginBottom: 12,
          marginTop: 12,
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Autocomplete
            id='concursos'
            options={concursosModalidad.sort((a, b) => {
              const categoriaA = buscarCategoria(a.categorías[0]);
              const categoriaB = buscarCategoria(b.categorías[0]);

              if (categoriaA.orden > categoriaB.orden) {
                return 1;
              }
              if (categoriaA.orden < categoriaB.orden) {
                return -1;
              } else {
                return 0;
              }
            })}
            groupBy={(option) => {
              return option.categorías[0];
            }}
            getOptionLabel={(option) =>
              option.tipo === 'Final'
                ? `Final - ${option.nombre || ''} - ${
                    categoriasFix(option.categorías) || ''
                  }`
                : option.tipo === 'Clasificacion'
                ? `${option.nombre || ''} - ${
                    categoriasFix(option.categorías) || ''
                  }`
                : `${option.tipo} ${
                    option.individual ? 'Individual' : 'por parejas'
                  } ${categoriasFix(option.categorías)}`
            }
            sx={{
              maxWidth: '50%',
              minWidth: '30%',
              '& .MuiInputBase-root': {
                color: tema.texto,
              },
              '& .MuiAutocomplete-popupIndicator': {
                color: tema.texto,
              },
              '& .MuiIconButton-root': {
                color: tema.texto,
              },
            }}
            renderInput={(params) => (
              <TextField {...params} label='Concursos' />
            )}
            value={buscarConcurso(selectedConcurso)}
            onChange={onHandleChange}
            renderOption={(params, option) => {
              return (
                <li {...params} key={option.id}>
                  <Texto
                    style={{
                      fontWeight: '400',
                      fontSize: RFPercentage(1.8),
                      marginLeft: 12,
                      color: 'black',
                    }}
                  >
                    {option.tipo === 'Final'
                      ? `Final - ${option.nombre || ''}`
                      : option.tipo === 'Clasificacion'
                      ? `${option.nombre || ''}`
                      : `${option.tipo} ${
                          option.individual ? 'Individual' : 'por parejas'
                        }`}
                  </Texto>
                </li>
              );
            }}
            renderGroup={(params) => {
              const categoria = buscarCategoria(params.group);
              return (
                <li key={params.key}>
                  <Texto
                    style={{
                      fontWeight: '600',
                      fontSize: RFPercentage(1.6),
                      marginLeft: 12,
                    }}
                  >
                    {categoria.nombre}
                  </Texto>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              );
            }}
          />
          <Tabs value={tab} onChange={handleChangeTab}>
            <Tab label='Clasificación' style={{ color: tema.texto }} />
            <Tab label='Tiradas' style={{ color: tema.texto }} />
            <Tab label='Información' style={{ color: tema.texto }} />
          </Tabs>
        </Box>
        {loading ? (
          <LinearProgress
            color={'secondary'}
            style={{ marginTop: 6, backgroundColor: tema.backgroundColor }}
          />
        ) : (
          <Box style={{ height: 10 }} />
        )}
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'flex-start',
          backgroundColor: tema.backgroundColor,
          borderRadius: 12,
          overflow: 'hidden',
          marginBottom: 12,
          padding: 12,
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <Box
              style={{
                height: height,
                maxHeight: height,
                width: width,
                overflowY: 'auto',
              }}
            >
              {/* {tab === 0 && concurso.tipo === 'Clasificacion' && (
              <ClasificacionConcurso concurso={concurso} tiradas={tiradas} />
            )} */}
              {tab === 0 && concurso.tipo === 'Final' && (
                <ClasificacionFinal concurso={concurso} tiradas={tiradas} />
              )}
              {tab === 0 &&
                ['Campeonato Regional', 'Campeonato de España'].includes(
                  concurso.tipo
                ) && (
                  <ClasificacionCampeonato
                    concurso={concurso}
                    tiradas={tiradas}
                  />
                )}
              {tab === 1 && concurso.tipo === 'Clasificacion' && (
                <TiradasConcurso concurso={concurso} tiradas={tiradas} />
              )}
              {tab === 1 && concurso.tipo !== 'Clasificacion' && (
                <TiradasFinal concurso={concurso} tiradas={tiradas} />
              )}
              {tab === 2 && (
                <InformacionConcurso concurso={concurso} fechas={fechas} />
              )}
            </Box>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

export default Concursos;
