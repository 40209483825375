import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import { UserState } from './Context/UsuarioContext';
import { Main } from './components/Screens/Main';
import { BrowserRouter } from 'react-router-dom';
import { TemaState } from './Context/TemaContext';
import { ConfigState } from './Context/ConfigContext';
import { DataState } from './Context/DataContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/es';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { theme } from './theme';
import './index.css';

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='es'>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <SnackbarProvider
            preventDuplicate
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <TemaState>
              <UserState>
                <DataState>
                  <ConfigState>
                    <Main />
                  </ConfigState>
                </DataState>
              </UserState>
            </TemaState>
          </SnackbarProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
