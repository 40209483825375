import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { useTema } from '../../Context/TemaContext.js';
import { ROUTES } from '../Router/routes.js';
import { RFPercentage } from '../../utils/Functions.js';
import ScrollRevealComponent from '../ScrollRevealComponent.js';
import Texto from '../Componentes/Texto.js';
import ModalidadesInfo from './Competiciones/ModalidadesInfo.js';
import { MovilInicio } from './MovilInicio.js';
import AnimacionTexto from '../AnimacionTexto.js';

export const WebScreen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const { tema } = useTema();

  let history = useHistory();

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        style={{
          width: '100%',
          paddingBottom: RFPercentage(10),
          ...(!isMobile && {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            height: 'Calc(100vh - 70px)',
          }),
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 'Calc(100vh - 70px)',
            flex: 1,
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <img
              src={require('../../assets/icono.png')}
              style={{
                height: '25vh',
                width: '25vh',
                marginBottom: 20,
                borderRadius: 24,
              }}
              alt='LOGO'
            />
            <Typography
              variant='h1'
              style={{
                fontSize: '5vw',
                fontWeight: 'bold',
                color: tema.colors[500],
                textShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                width: '80%',
              }}
            >
              RESULTADOS DE BOLOS
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              alignSelf: 'center',
              marginBottom: 24,
            }}
          >
            <a
              href='https://apps.apple.com/es/app/resultados-de-bolos/id1610548244'
              target='_blank'
              rel='noopener noreferrer'
              style={{
                minHeight: '80px',
                textDecoration: 'none',
                maxWidth: '45vw',
              }}
            >
              <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  alignItems: 'center',
                  backgroundColor: 'black',
                  borderRadius: 12,
                }}
              >
                <img
                  src={require('../../assets/appStore.png')}
                  style={{
                    height: '60px',
                    width: '60px',
                    margin: 10,
                  }}
                  alt='AppStore'
                />
                <Box
                  sx={{
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: '400',
                      fontSize: isMobile ? '3vw' : '0.9vw',
                      color: 'white',
                    }}
                  >
                    Disponible en
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: isMobile ? '3vw' : '1.3vw',
                      color: 'white',
                    }}
                  >
                    App Store
                  </Typography>
                </Box>
              </Paper>
            </a>
            <a
              href={`https://play.google.com/store/apps/details?id=com.saro.marcos&hl=es_PY&gl=US`}
              target='_blank'
              rel='noopener noreferrer'
              style={{
                minHeight: '80px',
                textDecoration: 'none',
                maxWidth: '45vw',
              }}
            >
              <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  alignItems: 'center',
                  backgroundColor: 'black',
                  borderRadius: 12,
                }}
              >
                <img
                  src={require('../../assets/googlePlay.png')}
                  style={{
                    height: '60px',
                    width: '60px',
                    margin: 10,
                  }}
                  alt='AppStore'
                />
                <Box
                  sx={{
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: '400',
                      fontSize: isMobile ? '3vw' : '0.9vw',
                      color: 'white',
                    }}
                  >
                    Disponible en
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: isMobile ? '3vw' : '1.3vw',
                      color: 'white',
                    }}
                  >
                    Google Play
                  </Typography>
                </Box>
              </Paper>
            </a>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: isMobile ? '75vh' : 'Calc(100vh - 70px)',
            flex: 1,
          }}
        >
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <MovilInicio />
            <Button
              size='large'
              variant='contained'
              onClick={() => {
                history.push(ROUTES.inicio);
              }}
              style={{
                fontSize: isMobile ? '5vw' : '2vw',
                fontWeight: '600',
                borderRadius: 60,
                backgroundColor: tema.colors[500],
                marginTop: 48,
                color: '#fff',
              }}
            >
              Aplicación
            </Button>
          </Box>
        </Box>
      </Box>
      <ScrollRevealComponent>
        <Box
          style={{
            minHeight: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AnimacionTexto
            style={{
              fontSize: isMobile ? '6vw' : '2vw',
              fontWeight: '600',
              color: tema.subtitulo,
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              width: '90%',
            }}
          />
        </Box>
      </ScrollRevealComponent>
      <ScrollRevealComponent>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: 48,
            paddingBottom: 48,
          }}
        >
          <Texto
            variant={'h2'}
            style={{
              fontSize: isMobile ? '6vw' : '3vw',
              fontWeight: '600',
              textAlign: 'center',
              width: '100%',
            }}
          >
            NUEVA PÁGINA WEB
          </Texto>
          <Texto
            variant={'h3'}
            style={{
              fontSize: isMobile ? '4vw' : '2vw',
              fontWeight: '600',
              textAlign: 'center',
              width: '100%',
            }}
          >
            ¡¡¡Ahora los bolos están disponibles en todos los dispositivos!!!
          </Texto>
        </Box>
      </ScrollRevealComponent>
      <ScrollRevealComponent>
        <Box
          style={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={require('../../assets/dispositivos.png')}
            style={{
              height: RFPercentage(50),
              maxWidth: '50vw',
              borderRadius: 24,
              objectFit: 'contain',
            }}
            alt='dispositivos'
          />
        </Box>
      </ScrollRevealComponent>
      <ScrollRevealComponent>
        <Box sx={{ paddingTop: isMobile ? 0 : RFPercentage(10) }}>
          <ModalidadesInfo />
        </Box>
      </ScrollRevealComponent>
      <Box
        sx={{
          paddingTop: RFPercentage(10),
          paddingBottom: RFPercentage(2),
          alignSelf: 'center',
        }}
      >
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.instagram.com/msc_apps/'
        >
          <img
            alt='instagram | msc_apps'
            src={require('../../assets/instagram.png')}
            style={{ height: RFPercentage(3), width: RFPercentage(3) }}
          />
        </a>
      </Box>
    </Box>
  );
};
